import React, { useState, useRef, useEffect } from "react";
import { apiUrl, axinst,processError} from "../axInst";
import '../hatul.css';
import {Button} from 'primereact/button';
import { useLocation } from 'react-router-dom'
import { InputText } from "primereact/inputtext"
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { FileUpload } from 'primereact/fileupload'
import { ProxyArticle } from './ProxyArticle'
export const NewsEdit = (props) =>{
    const location = useLocation();
    //const state = props.location.state;
    const toast = useRef()
    const [articles, setArticles] = useState(null)
    const [selectedA, setSelectedA] = useState(null)
 /*    const [fldType, setFldType] = useState(selectedA ? selectedA.type : '')
    const [fldTitle, setFldTitle] = useState(selectedA ? selectedA.titleA : null)
    const [fldText, setFldText] = useState(selectedA ? selectedA.textA : null)
    const [fldLink, setFldLink] = useState(selectedA ? selectedA.link : null) */
    const [fldType, setFldType] = useState('')
    const [fldTitle, setFldTitle] = useState(null)
    const [fldText, setFldText] = useState(null)
    const [fldLink, setFldLink] = useState( null)
    const articleChanged = useRef(false)
    const [fldMtrlFile, setFldMtrlFile] = useState()
    const fldMtrlStoredFile = useRef()
    const [pleaseWait, setPleaseWait] = useState(false)
    let choosenFileName

    useEffect(()=>{
        fetchArticles()
    },[])
    
       const fetchArticles = () => {
        
        axinst.get('getArticles/false/n/0/0', {
            timeout: 20000,
         })
        .then((response) => {
            const result = response.data
            setArticles(result)
            setSelectedA(null)
           
        })
        .catch((err)=>
            console.log(err)
        )
    }
    const fetchArticlebyId = (id) => {
        if (!id) 
           return
        axinst.get('article/getById/'+id)
        .then((response) => {
            setSelectedA(response.data)
            setFldType(response.data.type)
            setFldTitle(response.data.titleA)
            setFldText(response.data.textA)
            setFldLink(response.data.link)
           fldMtrlStoredFile.current=response.data.srvFileLink
           
        })
        .catch((err)=>
            console.log(err)
        )
    }
    const saveArticle = async () => {
        //"srvFileLink":fldMtrlStoredFile.current} id, titleA, textA, srvFileLink, type, link
        let id
        if (selectedA){
            id=selectedA.id
        }
        const proxy = new ProxyArticle(id, fldTitle, fldText, fldMtrlStoredFile.current, fldType, fldLink)
        axinst.put("/article/save", proxy)
        .then((response) => {
            id=response.data
            toast.current.show({severity:"success", summary:'Готово', detail: "Успешно сохранено"})
            articleChanged.current = false
            fetchArticles()
            
        })
        .catch(err=>{
            const errMsg = processError(err)
            toast.current.show({severity:"error", summary:"Ошибка", detail: errMsg})
        })
    }
   
    const clearFields=()=> {
        setSelectedA(null)
         setFldText("")
         setFldType("")
        setFldTitle("")
     setFldLink("")
      // fldMtrlStoredFile=null  
    }
    const uploadMtrlFile = (file) => {
        axinst.post('file/save', {"fileName":choosenFileName, "blob": file.result}, {timeout: 30000})
            .then(res => {
                    if (!res.data.startsWith("Ошибка")){
                        fldMtrlStoredFile.current = res.data
                        articleChanged.current = true
                        toast.current.show({severity:'success',summary:'Отлично!', detail:"Файл отправлен на сервер. Заполните остальные данные по материалу и сохраните их"})
                    }else{
                        toast.current.show({severity:'error',summary:'Ошибка', detail:res.data})
                     
                    }
            })
            .catch(err=>{
                const errMsg = processError(err)
                toast.current.show({severity:'error',summary:'Ошибка', detail:errMsg})
                
            })
            .finally(setPleaseWait(false))            
    }
    const uploadHandler = (files) => {
        setPleaseWait(true)
        const file = files.files.shift();
        const fileReader = new FileReader();
        choosenFileName =  file.name
        setFldMtrlFile(choosenFileName)
        fileReader.onload = (e) => {
            uploadMtrlFile(e.target);
        };
        fileReader.onloadstart = () => {setPleaseWait(true)}
        fileReader.readAsDataURL(file);
}
    const showImage=()=>{
        if (selectedA.image)    {
         const srcValue="data:image/png;base64,"+selectedA.image
         return(
           <img src={srcValue} style={{ width: '200px' }} alt="img" />
           )
         }   
     }
    
    const displayArticle = () => {
        return (
        <Panel>
        <Button className="p-button-rounded p-mr-3" icon="pi pi-plus" tooltip="Новая статья" tooltipOptions={{position: 'left'}}
                    onClick={()=>clearFields()}  /> 
        <div className="p-grid">
            <div  className="p-col-6" >
            
               Номер статьи {selectedA&&selectedA.id}  
                <div className="p-float-label p-my-3">
                        <InputText id="fldType" value={fldType} maxLength={20} style={{ width: '100%' }}
                                    onChange = {(e)=>{setFldType(e.target.value); articleChanged.current = true}}/>
                        <label htmlFor="fldType">Раздел сайта</label> 
                     
                </div>
                <div className="p-float-label p-my-3">
                        <InputText id="fldTitle" value={fldTitle} maxLength={200} style={{ width: '100%' }}  required
                                    onChange = {(e)=>{setFldTitle(e.target.value); articleChanged.current = true}}/>
                        <label htmlFor="fldTitle">Заголовок</label>
                </div>
                <div className="p-float-label p-my-3">
                                    <InputTextarea id="fldText" value={fldText} rows={5} cols={50} autoResize  required
                                    onChange = {(e)=>{setFldText(e.target.value); articleChanged.current = true}}/>
                        <label htmlFor="fldText">Текст </label>
                </div>
                <div className="p-float-label p-my-3">
                        <InputText id="fldLink" value={fldLink} maxLength={200} style={{ width: '100%' }}
                                    onChange = {(e)=>{setFldLink(e.target.value); articleChanged.current = true}}/>
                        <label htmlFor="fldLink">Ссылка на ресурс</label>        
                </div>
                <div className="p-d-flex p-jc-around">
                    <Button label="Выйти" onClick={props.history.goBack}></Button>
                    {articleChanged.current &&  
                        <Button label="Сохранить" onClick={saveArticle}></Button>}
                        
                </div>
         </div> 
         <div  className="p-col-6">
           <h6>Добавить рисунок </h6>
            <div className="p-inputgroup">
                        <InputText id="fldMtrlFile" value={fldMtrlFile} placeholder="Файл с рисунком" style={{width:'100px'}} />
                        <FileUpload mode="basic" name="document"  style={{width:'5rem', marginLeft:'1rem', marginTop:'0.5rem'}}
                            customUpload={true} uploadHandler={uploadHandler} 
                            auto chooseLabel=" ">
                        </FileUpload>
             </div>
            <div> {selectedA&&showImage()}  {!selectedA&&<img src='assets/images/hatul-logo.png' alt="img" />} </div>
            <div className="p-orange">
                <h6>Для форматирования текста используем тэги HTML:</h6>
                <p><b> b - </b> жирный шрифт</p>
                <p><b> i - </b> курсив</p>
                <p><b> p - </b> абзац</p>
                <p><b> h4, h5, h6 -  </b> заголовки</p>
                <p><b> ui - </b> список</p>
                <p><b> li - </b> элемент списка</p>
           
            </div>
            </div>
        </div>
        </Panel>
        )
    }

    const pageHeader = () => {
        return <div className="p-d-flex p-d-column" style={{padding:'5px'}}>
            <Button className="p-button-rounded p-ml-5" icon="pi pi-arrow-left" tooltip="Вернуться на предыдущую страницу"
                onClick={()=>props.history.goBack()}></Button>
            <div className="p-card-title p-ml-5 p-my-2">Редактирование статей</div>
        </div>
    }
    const onASelect = (value) => {
               setSelectedA(value)
               fetchArticlebyId(value.id)

    }
    const header = () => {
        return ( <h5>Список статей: </h5>)
    }

    return(
    <Panel>
         <div >{pageHeader()} </div >
    <div className="p-grid">
        <Toast ref={toast} position = {"top-left"} life='5000'></Toast>
        <div className="p-col-2">
            <DataTable value={articles} selection={selectedA} selectionMode="single" 
                scrollable scrollHeight="flex"
                onSelectionChange={e=>onASelect(e.value)}
                dataKey="id" responsiveLayout="scroll"
                emptyMessage="Нет данных">
                <Column field="titleA" header={header()}/>
            </DataTable>
        </div>
        <div className="p-col-8">
        {displayArticle()}
        </div>
    </div>
    </Panel>
    )}